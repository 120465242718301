/* eslint-disable */
export function setDateFunctions() {
  Date.prototype.getWeekNumber = function () {
    const today = new Date(+this)
    today.setHours(0, 0, 0, 0)
    today.setDate(today.getDate() - (today.getDay() || 7))
    return Math.ceil(((today - new Date(today.getFullYear(), 0, 1)) / 8.64e7 + 1) / 7)
  }
  Date.prototype.getFortnightNumber = function () {
    const today = new Date(+this)
    today.setHours(0, 0, 0, 0)
    today.setDate(today.getDate() + 4 - (today.getDay() || 15))
    return Math.ceil(((today - new Date(today.getFullYear(), 0, 1)) / 8.64e7 + 1) / 15)
  }
}
export function getIntervalDatesByPeriodicity(today, periodicity) {
  setDateFunctions()
  let week = 0
  let fortnight = 0
  let correction = 0
  let firstDay = {}
  let start = {}
  let end = {}
  switch (periodicity) {
    case 0:
      start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        .toISOString()
        .split('T')[0]
      end = start
      break
    case 1:
      week = today.getWeekNumber()
      firstDay = new Date(today.getFullYear(), 0, 1)
      correction = 6 - firstDay.getDay()
      start = new Date(today.getFullYear(), 0, (week - 2) * 7 + 3 + correction)
        .toISOString()
        .split('T')[0]
      end = new Date(today.getFullYear(), 0, (week - 2) * 7 + 9 + correction)
        .toISOString()
        .split('T')[0]
      break
    case 2:
      fortnight = today.getFortnightNumber()
      start = new Date(today.getFullYear(), 0, (fortnight - 1) * 15)
        .toISOString()
        .split('T')[0]
      end = new Date(today.getFullYear(), 0, (fortnight - 1) * 15 + 15)
        .toISOString()
        .split('T')[0]
      break
    case 3:
      start = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        .toISOString()
        .split('T')[0]
      end = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0]
      break
    case 4:
      start = new Date(today.getFullYear(), today.getMonth() - 3, 1)
        .toISOString()
        .split('T')[0]
      end = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0]
      break
    case 5:
      start = new Date(today.getFullYear(), today.getMonth() - 6, 1)
        .toISOString()
        .split('T')[0]
      end = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0]
      break
    case 6:
      start = new Date(today.getFullYear() - 1, today.getMonth(), 1)
        .toISOString()
        .split('T')[0]
      end = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0]
      break
    default:
      start = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        .toISOString()
        .split('T')[0]
      end = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0]
      break
  }
  return { startAt: start, endAt: end }
}
