<template>
  <div class="vuse-content-wrapper">
    <horizontal-menu :menuItems="menuItems" />

    <v-container fluid>
      <!-- <v-row align="center">
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Auditorías Pendientes'"
            :metric="stats.pendingEvaluations"
            :icon="'mdi-account-check-outline'"
            :loading="loading"
            :helpId="54"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Auditorías Aprobadas'"
            :metric="stats.approvedEvaluations"
            :icon="'mdi-briefcase-check-outline'"
            :loading="loading"
            :helpId="55"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Auditorías Reprobadas'"
            :metric="stats.reprovedEvaluations"
            :icon="'mdi-sitemap'"
            :loading="loading"
            :helpId="56"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Documentos Vencidos'"
            :metric="stats.expiredDocuments"
            :icon="'mdi-book-open-blank-variant'"
            :loading="loading"
            :helpId="57"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
      </v-row> -->
      <v-row class="mx-0 my-0 py-0" no-gutters>
        <dashboard-layout :moduleId="17" />
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import HorizontalMenu from '@/components/App/HorizontalMenu'
  // import SingleMetric from '@/components/Stats/SingleMetric'
  import DashboardLayout from '@/components/Dashboards/Layout'
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'Dashboard',
    components: {
      HorizontalMenu,
      // SingleMetric,
      DashboardLayout,
    },
    data: () => ({
      loading: false,
      // stats: {},
      menuItems: [
        {
          name: 'Formulación de Evaluaciones ',
          icon: 'mdi-account-group',
          link: '/business/supervision-control/evaluations',
        },
        {
          name: 'Evaluaciones asignadas',
          icon: 'mdi-calendar-check',
          link: '/business/supervision-control/assigned-evaluations',
        },
        {
          name: 'Gestor de Documentos',
          icon: 'mdi-text-box-multiple-outline',
          link: '/business/supervision-control/documents',
        },
        {
          name: 'Supervisiones',
          icon: 'mdi-account-supervisor-outline',
          link: '/business/supervision-control/supervisions',
        },
      ],
    }),
    created() {
      // this.getStats()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    methods: {
      // getStats() {
      //   this.loading = true
      //   this.$axios
      //     .get(
      //       'stats-management/control-and-supervision-stats?company_id=' + this.company.id
      //     )
      //     .then((response) => {
      //       this.stats = response.data
      //       this.loading = false
      //     })
      // },
    },
  }
</script>
