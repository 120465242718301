<template>
  <v-container fluid style="padding: 0px" class="dashboard-layout">
    <v-app-bar color="primary lighten-2" dark height="auto">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" @click="drawer = !drawer" />
        </template>
        <span>Ver elementos del tablero</span>
      </v-tooltip>

      <v-select
        class="mr-1"
        style="width: 40px"
        persistent-placeholder
        hide-details
        dense
        dark
        v-model="periodicity"
        filled
        :rules="[rules.required]"
        item-value="id"
        item-text="name"
        :items="itemsPeriodicity"
        label="Periodicidad"
      />

      <v-menu
        v-model="startAtMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="width: 30px"
            class="mr-1"
            dense
            filled
            hide-details
            :rules="[rules.required]"
            v-model="startAt"
            label="Desde"
            readonly
            v-bind="attrs"
            v-on="on"
            type="date"
          />
        </template>
        <v-date-picker v-model="startAt" @input="startAtMenu = false" />
      </v-menu>
      <v-menu
        v-model="endAtMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="width: 30px"
            class="mr-1"
            dense
            filled
            hide-details
            :rules="[rules.required]"
            v-model="endAt"
            label="Hasta"
            readonly
            v-bind="attrs"
            v-on="on"
            type="date"
          />
        </template>
        <v-date-picker v-model="endAt" @input="endAtMenu = false" />
      </v-menu>

      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="white" @click="index++">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Aplicar filtros</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="white" @click="saveLayout()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Guardar tablero</span>
      </v-tooltip>
    </v-app-bar>

    <v-app>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        right
        style="width: 350px !important"
      >
        <v-text-field
          v-model="search"
          label="Buscar Métrica o Gráfico"
          flat
          dark
          class="primary"
          solo
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        >
          <v-btn slot="append" icon color="white" @click="drawer = false">
            <v-icon>mdi-notification-clear-all</v-icon>
          </v-btn>
          ></v-text-field
        >
        <v-treeview
          :key="treeIndex"
          class="px-2"
          item-text="title"
          v-model="selectedGraphics"
          :items="groupedGraphics"
          :search="search"
          :filter="filter"
          :open.sync="open"
          :multiple-active="true"
          :selection-type="'independent'"
          return-object
          open-all
          :selectable="false"
          dense
          hoverable
          open-on-click
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="item.children">
              {{
                open.find((item2) => item2.title === item.title) !== undefined
                  ? 'mdi-folder-open'
                  : 'mdi-folder'
              }}
            </v-icon>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="
                    !item.children &&
                    layout.find((graphic) => graphic.id === item.id) === undefined
                  "
                  class="ma-1"
                  x-small
                  inline
                  icon
                  fab
                  color="primary"
                  @click="addGraphic(item)"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span>Añadir al tablero</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-if="
                    !item.children &&
                    layout.find((graphic) => graphic.id === item.id) !== undefined
                  "
                  class="ma-1"
                  x-small
                  inline
                  icon
                  fab
                  color="primary"
                  @click="removeGraphic(item.id)"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              <span>Remover del tablero</span>
            </v-tooltip>
          </template>
          <template v-slot:label="{ item }">
            <span>{{ item.title }}</span>
          </template>
        </v-treeview>
      </v-navigation-drawer>
      <v-row class="mx-o my-0">
        <v-col cols="12">
          <grid-layout
            :key="index"
            :layout.sync="layout"
            :row-height="30"
            :responsive="false"
            :is-draggable="true"
            :is-resizable="false"
            :vertical-compact="true"
            :horizontal-compact="true"
          >
            <grid-item
              v-for="graphic in layout"
              :key="graphic.i"
              :x="graphic.x"
              :y="graphic.y"
              :w="graphic.w"
              :h="graphic.h"
              :i="graphic.i"
            >
              <graphic-card
                :moduleId="moduleId"
                :graphic="graphic"
                :generalPeriodicity="periodicity"
                :generalStartAt="startAt"
                :generalEndAt="endAt"
                :allowedCompanies="allowedCompanies"
                @deleteGraphic="removeGraphic($event)"
              />
            </grid-item>
          </grid-layout>
        </v-col>
      </v-row>
    </v-app>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import itemsPeriodicity from '@/data/periodicity'
  import { getIntervalDatesByPeriodicity } from '@/utils/dateFunctions'
  import { decryptData } from '@/utils/encryption'
  import { GridLayout, GridItem } from 'vue-grid-layout'
  import GraphicCard from '@/components/Dashboards/GraphicCard'
  const today = new Date()

  export default {
    name: 'Layout',
    props: {
      moduleId: {
        type: Number,
        required: true,
        default: 1,
      },
    },
    components: {
      GraphicCard,
      GridLayout,
      GridItem,
    },
    data: () => ({
      allowedCompanies: [],
      drawer: false,
      index: 0,
      treeIndex: 0,
      layout: [],
      colNum: 12,
      startAtMenu: false,
      endAtMenu: false,
      loading: false,
      activeGrph2: false,
      activeGrph3: false,
      show: false,
      graphics: [],
      groupedGraphics: [],
      selectedGraphics: [],
      open: [],
      search: null,
      caseSensitive: false,
      itemsPeriodicity: itemsPeriodicity,
      periodicity: 3,
      startAt: new Date(today.getFullYear(), today.getMonth() - 1, 1)
        .toISOString()
        .split('T')[0],
      endAt: new Date(today.getFullYear(), today.getMonth(), 0)
        .toISOString()
        .split('T')[0],
      rules: {
        required: (v) => v !== null || 'Requerido.',
      },
    }),
    created() {
      this.getCustomGraphics()
      this.getAllowedCompanies()
    },

    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      userId() {
        return decryptData(this.$session.get('userId'))
      },
      filter() {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      },
      // layout: {
      //   get() {
      //     return this.selectedGraphics
      //   },
      //   set(newValue) {
      //     return newValue
      //   },
      // },
    },
    watch: {
      groupGraphics(newValue) {
        this.open = newValue
      },
      periodicity(newValue) {
        const interval = this.getIntervalDatesByPeriodicity(today, newValue)
        this.startAt = interval.startAt
        this.endAt = interval.endAt
      },
    },
    methods: {
      getIntervalDatesByPeriodicity: getIntervalDatesByPeriodicity,

      itemTitle(item) {
        let result = item.i
        if (item.static) {
          result += ' - Static'
        }
        return result
      },

      async getCustomGraphics() {
        const queryParams =
          '?limit=50&user_id=' +
          this.userId +
          '&module_id=' +
          this.moduleId +
          '&level_id=' +
          this.company.level_id
        this.$axios.get('get-user-graph' + queryParams).then((response) => {
          this.graphics = response.data.graphics.map((item) => {
            switch (item.type) {
              case 0:
                item.typeText = 'Métricas Globales'
                break
              case 1:
                item.typeText = 'Gráficos'
                break
              case 2:
                item.typeText = 'Reportes'
                break
              default:
                item.typeText = ''
                break
            }
            return item
          })
          if (response.data.userGraphics.length > 0) {
            const data = response.data.userGraphics
            this.layout = data.map((item) => {
              switch (item.graphic.type) {
                case 0:
                  item.graphic.typeText = 'Métricas Globales'
                  break
                case 1:
                  item.graphic.typeText = 'Gráficos'
                  break
                case 2:
                  item.graphic.typeText = 'Reportes'
                  break
                default:
                  item.graphic.typeText = ''
                  break
              }
              item.graphic.userGraphicId = item.id
              item.graphic.x = item.x
              item.graphic.y = item.y
              item.graphic.w = item.w
              item.graphic.h = item.h
              return item.graphic
            })
          } else {
            this.layout = this.graphics
          }
          this.createGraphicsTree()
          this.index++
        })
      },

      createGraphicsTree() {
        this.groupedGraphics = []
        this.groupedGraphics = Object.assign(
          this.groupedGraphics,
          this.groupGraphics(this.graphics)
        )
      },

      groupGraphics(list) {
        const tree = []
        const keys = [...new Set(list.map((item) => item.typeText))]
        keys.forEach((type) => {
          tree.push({
            title: type,
            children: list.filter((graphic) => graphic.typeText === type),
          })
        })
        return tree
      },

      getAllowedCompanies() {
        this.$axios
          .get('graph-management/get-allowed-companies?company_id=' + this.company.id)
          .then((response) => {
            this.allowedCompanies = response.data.companies
          })
      },

      saveLayout() {
        this.$axios
          .post('user-graphics-mass-create', {
            user_id: this.userId,
            module_id: this.moduleId,
            userGraphic: this.layout.map((graphic) => {
              return {
                id: graphic.userGraphicId ? graphic.userGraphicId : null,
                graphic_id: graphic.id,
                x: graphic.x,
                y: graphic.y,
                w: graphic.w,
                h: graphic.h,
              }
            }),
          })
          .then((response) => {
            if (response.data.message) {
              this.$swal({
                title: '¡Listo!',
                text: 'Grilla actualizada',
                icon: 'success',
              })
            }
          })
      },
      addGraphic(graphic) {
        this.layout.push(graphic)
        this.treeIndex++
      },
      removeGraphic(graphicId) {
        this.layout = this.layout.filter((item) => item.id !== graphicId)
        this.treeIndex++
      },
    },
  }
</script>
<style lang="scss">
  .dashboard-layout {
    .v-application--is-ltr .v-overflow-btn .v-label {
      color: white !important;
    }
    .vue-grid-layout {
      background: #eee;
    }
    .vue-grid-item:not(.vue-grid-placeholder) {
      /* background: #ccc; */
      border: 1px solid #ccc;
    }
    .vue-grid-item {
      touch-action: none;
    }
    .vue-grid-item .static {
      background: #cce;
    }
    .vue-grid-item .text {
      font-size: 24px;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
      width: 100%;
    }
    .vue-grid-item .no-drag {
      height: 100%;
      width: 100%;
    }
    .vue-grid-item .minMax {
      font-size: 12px;
    }
    .vue-grid-item .add {
      cursor: pointer;
    }
    // .vue-draggable-handle {
    //   position: absolute;
    //   width: 20px;
    //   height: 20px;
    //   top: 0;
    //   left: 0;
    //   background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    //     no-repeat;
    //   background-position: bottom right;
    //   padding: 0 8px 8px 0;
    //   background-repeat: no-repeat;
    //   background-origin: content-box;
    //   box-sizing: border-box;
    //   cursor: pointer;
    // }
    .layoutJSON {
      background: #ddd;
      border: 1px solid black;
      margin-top: 10px;
      padding: 10px;
    }
    .columns {
      -moz-columns: 250px;
      -webkit-columns: 250px;
      columns: 250px;
    }

    .v-treeview-node__root {
      padding: 0px;
      justify-content: flex-end;
    }
    .v-treeview-node__level {
      width: 15px;
    }
    .v-subheader {
      height: auto;
    }
    .v-treeview-node__content {
      align-items: center;
    }
    .v-application--is-ltr .v-treeview-node__toggle {
      width: 5px;
    }
    // .v-treeview-node__children {
    //   width: 99%;
    //   float: right;
    // }
  }
  .v-application--is-ltr .v-treeview-node__content {
    margin-left: 0px;
  }
</style>
