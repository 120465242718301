var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"dashboard-layout",staticStyle:{"padding":"0px"},attrs:{"fluid":""}},[_c('v-app-bar',{attrs:{"color":"primary lighten-2","dark":"","height":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.drawer = !_vm.drawer}}},'v-app-bar-nav-icon',attrs,false),on))]}}])},[_c('span',[_vm._v("Ver elementos del tablero")])]),_c('v-select',{staticClass:"mr-1",staticStyle:{"width":"40px"},attrs:{"persistent-placeholder":"","hide-details":"","dense":"","dark":"","filled":"","rules":[_vm.rules.required],"item-value":"id","item-text":"name","items":_vm.itemsPeriodicity,"label":"Periodicidad"},model:{value:(_vm.periodicity),callback:function ($$v) {_vm.periodicity=$$v},expression:"periodicity"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"width":"30px"},attrs:{"dense":"","filled":"","hide-details":"","rules":[_vm.rules.required],"label":"Desde","readonly":"","type":"date"},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startAtMenu),callback:function ($$v) {_vm.startAtMenu=$$v},expression:"startAtMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startAtMenu = false}},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"width":"30px"},attrs:{"dense":"","filled":"","hide-details":"","rules":[_vm.rules.required],"label":"Hasta","readonly":"","type":"date"},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endAtMenu),callback:function ($$v) {_vm.endAtMenu=$$v},expression:"endAtMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endAtMenu = false}},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}})],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.index++}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Aplicar filtros")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.saveLayout()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Guardar tablero")])])],1),_c('v-app',[_c('v-navigation-drawer',{staticStyle:{"width":"350px !important"},attrs:{"absolute":"","right":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-text-field',{staticClass:"primary",attrs:{"label":"Buscar Métrica o Gráfico","flat":"","dark":"","solo":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-btn',{attrs:{"slot":"append","icon":"","color":"white"},on:{"click":function($event){_vm.drawer = false}},slot:"append"},[_c('v-icon',[_vm._v("mdi-notification-clear-all")])],1),_vm._v(" >")],1),_c('v-treeview',{key:_vm.treeIndex,staticClass:"px-2",attrs:{"item-text":"title","items":_vm.groupedGraphics,"search":_vm.search,"filter":_vm.filter,"open":_vm.open,"multiple-active":true,"selection-type":'independent',"return-object":"","open-all":"","selectable":false,"dense":"","hoverable":"","open-on-click":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.children)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.open.find(function (item2) { return item2.title === item.title; }) !== undefined ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                  !item.children &&
                  _vm.layout.find(function (graphic) { return graphic.id === item.id; }) === undefined
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"x-small":"","inline":"","icon":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.addGraphic(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Añadir al tablero")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(
                  !item.children &&
                  _vm.layout.find(function (graphic) { return graphic.id === item.id; }) !== undefined
                )?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"x-small":"","inline":"","icon":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.removeGraphic(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Remover del tablero")])])]}},{key:"label",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.title))])]}}]),model:{value:(_vm.selectedGraphics),callback:function ($$v) {_vm.selectedGraphics=$$v},expression:"selectedGraphics"}})],1),_c('v-row',{staticClass:"mx-o my-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('grid-layout',{key:_vm.index,attrs:{"layout":_vm.layout,"row-height":30,"responsive":false,"is-draggable":true,"is-resizable":false,"vertical-compact":true,"horizontal-compact":true},on:{"update:layout":function($event){_vm.layout=$event}}},_vm._l((_vm.layout),function(graphic){return _c('grid-item',{key:graphic.i,attrs:{"x":graphic.x,"y":graphic.y,"w":graphic.w,"h":graphic.h,"i":graphic.i}},[_c('graphic-card',{attrs:{"moduleId":_vm.moduleId,"graphic":graphic,"generalPeriodicity":_vm.periodicity,"generalStartAt":_vm.startAt,"generalEndAt":_vm.endAt,"allowedCompanies":_vm.allowedCompanies},on:{"deleteGraphic":function($event){return _vm.removeGraphic($event)}}})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }