<template>
  <component
    :is="customComponent"
    :graphic="graphic"
    :generalPeriodicity="generalPeriodicity"
    :generalStartAt="generalStartAt"
    :generalEndAt="generalEndAt"
    :allowedCompanies="allowedCompanies"
    @deleteGraphic="$emit('deleteGraphic', $event)"
  />
</template>

<script>
  export default {
    name: 'GraphicCard',
    components: {},
    props: {
      graphic: {
        type: Object,
        default: () => {},
      },
      generalStartAt: {
        type: String,
        required: true,
      },
      generalEndAt: {
        type: String,
        required: true,
      },
      generalPeriodicity: {
        type: Number,
        required: true,
      },
      moduleId: {
        type: Number,
        default: 1,
      },
      allowedCompanies: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      modulePath() {
        switch (this.moduleId) {
          case 1:
            return 'CorporateStructure'
          case 17:
            return 'SupervisionControl'
          default:
            return 'CorporateStructure'
        }
      },
      customComponent() {
        if (this.graphic && this.graphic.component) {
          return () =>
            import(
              `@/components/Dashboards/${this.modulePath}/${this.graphic.component}.vue`
            )
        } else {
          return ''
        }
      },
    },

    data() {
      return {
        progress: 86,
        activeGrph: true,
        show: false,
        dialogHelp: false,
        dataChart: {
          options: {},
          series: [],
        },
        itemsShare: [
          {
            title: 'WhatsApp',
          },
          {
            title: 'E-mail',
          },
          {
            title: 'Telegram',
          },
        ],
      }
    },
    methods: {
      showCard() {
        this.activeGrph = !this.activeGrph
      },
    },
  }
</script>

<style lang="scss" scoped>
  .profile-row {
    position: absolute;
    top: -50px !important;
    width: 100%;
  }
</style>
